import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Color from "src/styles/colors";
import styled from "styled-components";

export const Title = styled.span`
  font-size: 2em;
  margin-top: 25px;
`;

export const Subtitle = styled.span`
  font-size: 1.2em;
`;

export const Container = styled.div`
  text-align: center;
  padding: 3rem;
  height: 100vh;
  width: 100vw;
  background-color: #454d55;
  color: #fff;
  box-sizing: border-box;
`;

export const VersionNumber = styled.span`
  margin-left: 5px;
`;

export const Button = styled.button`
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 16px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  color: #fff;
  min-width: 150px;
  font-family: inherit;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.65;
  }

  &.primary {
    background-color: #3f6791;
    border-color: #3f6791;

    &:hover:not(:disabled) {
      background-color: #335476;
      border-color: #304e6d;
    }

    &:focus {
      background-color: #335476;
      border-color: #304e6d;
      box-shadow: 0 0 0 0 rgb(92 126 162 / 50%);
    }

    &:active:not(:disabled) {
      background-color: #304e6d;
      border-color: #2c4765;
    }
  }

  &.success {
    background-color: #00bc8c;
    border-color: #00bc8c;
    &:hover:not(:disabled) {
      background-color: #009670;
      border-color: #008966;
    }

    &:focus {
      background-color: #009670;
      border-color: #008966;
      box-shadow: 0 0 0 0 rgb(38 198 157 / 50%);
    }

    &:active:not(:disabled) {
      background-color: #008966;
      border-color: #007c5d;
    }
  }

  &.danger {
    background-color: #e74c3c;
    border-color: #e74c3c;

    &:hover:not(:disabled) {
      background-color: #e12e1c;
      border-color: #d62c1a;
    }

    &:focus {
      background-color: #e12e1c;
      border-color: #d62c1a;
      box-shadow: 0 0 0 0 hsla(6, 78%, 64%, 0.5);
    }

    &:active:not(:disabled) {
      background-color: #d62c1a;
      border-color: #ca2a19;
    }
  }

  &.discord {
    color: #fff;
    background-color: #5865f2;
    border-color: #5865f2;

    &:hover {
      color: #fff;
      background-color: #4650c1;
      border-color: #4049b0;
    }

    &:active:not(:disabled) {
      color: #fff;
      background-color: #4049b0;
      border-color: #39419d;
    }
  }
`;

export const Hr = styled.div`
  width: 80%;
  margin: 20px;
  height: 1px;
  background-color: lightgrey;
`;

export const Input = styled.input`
  height: 38px;
  color: white;
  background-color: inherit;
  border: 1px solid rgb(108, 117, 125);
  border-radius: 4px;
  padding: 0 6px;
  text-align: center;
  font-size: 1.2em;
  width: 150px;
`;

export const Select = styled.select`
  color: white;
  background-color: ${Color.COLOR_SECONDARY_MENU_BACKGROUND};
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  width: 150px;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out;
  margin: 15px 0;
`;

export const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

export const Checkbox = styled.div`
  display: inline;
  width: 100%;
  padding-left: 50px;

  > input {
    transform: translateY(1px);
  }
`;

export const ErrorCode = styled.div`
  color: #fff;
  font-size: 7rem;
  position: relative;
  line-height: 1;

  @-webkit-keyframes noise-anim {
    0% {
      clip: rect(81px, 9999px, 74px, 0);
    }
    5% {
      clip: rect(2px, 9999px, 29px, 0);
    }
    10% {
      clip: rect(1px, 9999px, 15px, 0);
    }
    15% {
      clip: rect(50px, 9999px, 8px, 0);
    }
    20% {
      clip: rect(99px, 9999px, 79px, 0);
    }
    25% {
      clip: rect(85px, 9999px, 15px, 0);
    }
    30% {
      clip: rect(41px, 9999px, 53px, 0);
    }
    35% {
      clip: rect(22px, 9999px, 36px, 0);
    }
    40% {
      clip: rect(73px, 9999px, 79px, 0);
    }
    45% {
      clip: rect(82px, 9999px, 100px, 0);
    }
    50% {
      clip: rect(47px, 9999px, 96px, 0);
    }
    55% {
      clip: rect(10px, 9999px, 44px, 0);
    }
    60% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    65% {
      clip: rect(29px, 9999px, 88px, 0);
    }
    70% {
      clip: rect(12px, 9999px, 44px, 0);
    }
    75% {
      clip: rect(25px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(38px, 9999px, 35px, 0);
    }
    85% {
      clip: rect(20px, 9999px, 96px, 0);
    }
    90% {
      clip: rect(58px, 9999px, 44px, 0);
    }
    95% {
      clip: rect(72px, 9999px, 41px, 0);
    }
    100% {
      clip: rect(69px, 9999px, 72px, 0);
    }
  }

  @keyframes noise-anim {
    0% {
      clip: rect(81px, 9999px, 74px, 0);
    }
    5% {
      clip: rect(2px, 9999px, 29px, 0);
    }
    10% {
      clip: rect(1px, 9999px, 15px, 0);
    }
    15% {
      clip: rect(50px, 9999px, 8px, 0);
    }
    20% {
      clip: rect(99px, 9999px, 79px, 0);
    }
    25% {
      clip: rect(85px, 9999px, 15px, 0);
    }
    30% {
      clip: rect(41px, 9999px, 53px, 0);
    }
    35% {
      clip: rect(22px, 9999px, 36px, 0);
    }
    40% {
      clip: rect(73px, 9999px, 79px, 0);
    }
    45% {
      clip: rect(82px, 9999px, 100px, 0);
    }
    50% {
      clip: rect(47px, 9999px, 96px, 0);
    }
    55% {
      clip: rect(10px, 9999px, 44px, 0);
    }
    60% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    65% {
      clip: rect(29px, 9999px, 88px, 0);
    }
    70% {
      clip: rect(12px, 9999px, 44px, 0);
    }
    75% {
      clip: rect(25px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(38px, 9999px, 35px, 0);
    }
    85% {
      clip: rect(20px, 9999px, 96px, 0);
    }
    90% {
      clip: rect(58px, 9999px, 44px, 0);
    }
    95% {
      clip: rect(72px, 9999px, 41px, 0);
    }
    100% {
      clip: rect(69px, 9999px, 72px, 0);
    }
  }

  &:after {
    content: attr(data-text);
    position: absolute;
    text-shadow: -1px 0 #e74a3b;
    color: #fff;
    background: #454d55;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    transform: translateX(-99%);
  }

  @-webkit-keyframes noise-anim-2 {
    0% {
      clip: rect(82px, 9999px, 93px, 0);
    }
    5% {
      clip: rect(13px, 9999px, 71px, 0);
    }
    10% {
      clip: rect(22px, 9999px, 90px, 0);
    }
    15% {
      clip: rect(17px, 9999px, 11px, 0);
    }
    20% {
      clip: rect(21px, 9999px, 91px, 0);
    }
    25% {
      clip: rect(37px, 9999px, 33px, 0);
    }
    30% {
      clip: rect(37px, 9999px, 80px, 0);
    }
    35% {
      clip: rect(6px, 9999px, 49px, 0);
    }
    40% {
      clip: rect(86px, 9999px, 60px, 0);
    }
    45% {
      clip: rect(46px, 9999px, 83px, 0);
    }
    50% {
      clip: rect(16px, 9999px, 83px, 0);
    }
    55% {
      clip: rect(73px, 9999px, 36px, 0);
    }
    60% {
      clip: rect(58px, 9999px, 98px, 0);
    }
    65% {
      clip: rect(20px, 9999px, 93px, 0);
    }
    70% {
      clip: rect(43px, 9999px, 67px, 0);
    }
    75% {
      clip: rect(66px, 9999px, 75px, 0);
    }
    80% {
      clip: rect(66px, 9999px, 41px, 0);
    }
    85% {
      clip: rect(17px, 9999px, 47px, 0);
    }
    90% {
      clip: rect(49px, 9999px, 68px, 0);
    }
    95% {
      clip: rect(8px, 9999px, 85px, 0);
    }
    100% {
      clip: rect(83px, 9999px, 50px, 0);
    }
  }

  @keyframes noise-anim-2 {
    0% {
      clip: rect(82px, 9999px, 93px, 0);
    }
    5% {
      clip: rect(13px, 9999px, 71px, 0);
    }
    10% {
      clip: rect(22px, 9999px, 90px, 0);
    }
    15% {
      clip: rect(17px, 9999px, 11px, 0);
    }
    20% {
      clip: rect(21px, 9999px, 91px, 0);
    }
    25% {
      clip: rect(37px, 9999px, 33px, 0);
    }
    30% {
      clip: rect(37px, 9999px, 80px, 0);
    }
    35% {
      clip: rect(6px, 9999px, 49px, 0);
    }
    40% {
      clip: rect(86px, 9999px, 60px, 0);
    }
    45% {
      clip: rect(46px, 9999px, 83px, 0);
    }
    50% {
      clip: rect(16px, 9999px, 83px, 0);
    }
    55% {
      clip: rect(73px, 9999px, 36px, 0);
    }
    60% {
      clip: rect(58px, 9999px, 98px, 0);
    }
    65% {
      clip: rect(20px, 9999px, 93px, 0);
    }
    70% {
      clip: rect(43px, 9999px, 67px, 0);
    }
    75% {
      clip: rect(66px, 9999px, 75px, 0);
    }
    80% {
      clip: rect(66px, 9999px, 41px, 0);
    }
    85% {
      clip: rect(17px, 9999px, 47px, 0);
    }
    90% {
      clip: rect(49px, 9999px, 68px, 0);
    }
    95% {
      clip: rect(8px, 9999px, 85px, 0);
    }
    100% {
      clip: rect(83px, 9999px, 50px, 0);
    }
  }

  &:before {
    content: attr(data-text);
    position: absolute;
    text-shadow: 1px 0 #4e73df;
    color: #fff;
    background: #454d55;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    transform: translateX(1px);
  }
`;

export const ErrorTag = styled.p`
  margin: 0 0 1rem 0;
  font-size: 1.25em;
  font-weight: 300;
`;

export const ErrorText = styled.div`
  background-color: #343a40;
  border-radius: 10px;
  padding: 10px;
  color: #dc3545;
  margin: 0 0 1rem 0;
`;
