import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ErrorPage, FlightStrips, Login, RedirectIos } from "src/pages";
import { getVnasConfiguration, useAppDispatch } from "src/redux";
import { PrivateRoute } from "src/routes";

const isRunningOnIos = () => /iPad|iPhone|iPod/.test(navigator.userAgent);

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getVnasConfiguration());
  }, []);

  if (isRunningOnIos()) {
    return <RedirectIos />;
  }

  return (
    <ErrorBoundary fallbackRender={ErrorPage}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/:id" element={<FlightStrips />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
