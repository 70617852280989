import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StripItemType } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import { DISPLACE_ANIMATION_MS, DROP_ANIMATION_MS } from "src/utils";
import styled, { css } from "styled-components";

export const Spinner = styled(FontAwesomeIcon)`
  color: #ffffff50;
  height: 80px;
  width: 80px;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  position: absolute;
`;

export const Bay = styled.div`
  height: calc(100vh - 76px);
  width: 100vw;
  background-color: #2f2f2f;
  position: absolute;
  bottom: 0;
  overflow-x: scroll;
  display: flex;
  * {
    flex-shrink: 0;
  }
`;

export const RackContainer = styled.div`
  width: 575px;
  position: relative;

  &::before {
    content: " ";
    background-image: url(/img/rack.png);
    position: absolute;
    width: 550px;
    height: 100%;
  }
`;

export const Rack = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 150;
`;

export const UpTriangle = styled(Triangle)`
  top: 20px;
  border-bottom: 35px solid #5c5c5c7a;
`;

export const DownTriangle = styled(Triangle)`
  bottom: 20px;
  border-top: 35px solid #5c5c5c7a;
`;

export const StripItemPlaceholdersContainer = styled.div`
  position: absolute;
`;

export const StripItemPlaceholder = styled.div`
  height: 76px;
  width: 550px;
`;

const displaceMs = `${DISPLACE_ANIMATION_MS}ms`;

export const StripHolder = styled.div<{
  $isHoldingCtrl: boolean;
  $isHoldingAlt: boolean;
  $isReadOnly: boolean;
  $isHidden: boolean;
  $isDisplaced: boolean;
  $animationsDisabled: boolean;
  $isOffset: boolean;
}>`
  position: relative;
  width: 545px;
  height: 66px;
  white-space: nowrap;
  border: 5px solid #cacddf;
  border-right: 0px;
  background-color: #b9bbca;
  color: black;
  opacity: ${(p) => p.$isHidden && "0"};
  transition: ${(p) => !p.$animationsDisabled && css`transform ${displaceMs} cubic-bezier(0.2, 0, 0, 1)`};

  cursor: ${(p) => {
    if (p.$isReadOnly) {
      return css`default`;
    }
    if (p.$isHoldingCtrl) {
      return css`pointer`;
    }
    if (p.$isHoldingAlt) {
      return css`crosshair`;
    }
    return css`grab`;
  }};

  &.selected {
    &:after {
      content: " ";
      position: absolute;
      width: calc(545px - 2px);
      height: 100%;
      top: -2px;
      left: -2px;
      border: 2px solid black;
      pointer-events: none;
    }
  }

  ${(p) => {
    if (p.$isDisplaced) {
      if (p.$isOffset) {
        return css`
          transform: translate(20px, -100%) perspective(1000px) rotateY(-10deg) scale(0.97);
        `;
      }
      return css`
        transform: translateY(-100%);
      `;
    }
    if (p.$isOffset) {
      return css`
        transform: perspective(1000px) rotateY(-10deg) translateX(20px) scale(0.97);
      `;
    }
    return undefined;
  }}

  &::before {
    content: " ";
    position: absolute;
    width: calc(100% + 5px);
    height: 1px;
    background-color: darkgrey;
    left: -5px;
    bottom: -5px;
  }
`;

export const DisconnectedX = styled.div`
  background-image: url(/img/strip-x.png);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  pointer-events: none;
`;

export const FlightPlanStrip = styled.div`
  position: relative;
  height: 100%;
  background-color: #f6f9f2;

  * {
    position: absolute;
  }
`;

export const StripField = styled.div<{ x: number; y: number; fontSize?: number }>`
  font-family: "Printer";
  left: ${(p) => `${p.x}px`};
  bottom: ${(p) => `${p.y}px`};
  font-size: ${(p) => (p.fontSize ? `${p.fontSize}px` : "12px")};
  line-height: ${(p) => (p.fontSize ? `${p.fontSize}px` : "12px")};
`;

export const Barcode = styled.div`
  left: 55px;
  bottom: 2px;
  font-family: "Barcode";
  font-size: 16px;
  line-height: 16px;
`;

export const VerticalLine = styled.div<{ x: number }>`
  left: ${(p) => `${p.x}px`};
  height: 100%;
  width: 1px;
  transform: scaleX(0.5);
  background: black;
`;

export const HorizontalLine = styled.div<{ x: number; y: number; width: number }>`
  left: ${(p) => `${p.x}px`};
  bottom: ${(p) => `${p.y}px`};
  width: ${(p) => `${p.width}px`};
  height: 1px;
  transform: scaleY(0.5);
  background: black;
`;

export const AnnotationBox = styled.input<{ $x: number; $y: number; $height: number; $width: number; $textAlign: string; $grabbable: boolean }>`
  left: ${(p) => `${p.$x}px`};
  bottom: ${(p) => `${p.$y}px`};
  width: ${(p) => `${p.$width}px`};
  height: ${(p) => `${p.$height}px`};
  line-height: 22px;
  font-size: 16px;
  border: none;
  padding: 0;
  overflow: hidden;
  font-family: "Handwritten";
  font-weight: bolder;
  text-transform: uppercase;
  text-align: ${(p) => p.$textAlign};
  background-color: transparent;
  ${(p) =>
    p.$grabbable &&
    css`
      cursor: grab;
    `};

  &:focus {
    outline: none;
  }
`;

export const AnnotationArea = styled.textarea<{
  $disablePointer: boolean;
  $grabbable: boolean;
  $height: number;
  $textAlign: string;
  $width: number;
  $x: number;
  $y: number;
}>`
  left: ${(p) => `${p.$x}px`};
  bottom: ${(p) => `${p.$y}px`};
  width: ${(p) => `${p.$width}px`};
  height: ${(p) => `${p.$height}px`};
  line-height: 22px;
  font-size: 16px;
  border: none;
  resize: none;
  padding: 0;
  pointer-events: ${(p) => (p.$disablePointer ? "none" : "auto")};
  overflow: hidden;
  font-family: "Handwritten";
  font-weight: bolder;
  text-transform: uppercase;
  text-align: ${(p) => p.$textAlign};
  background-color: transparent;
  ${(p) =>
    p.$grabbable &&
    css`
      cursor: grab;
    `};

  &:focus {
    outline: none;
  }
`;

export const Separator = styled.div<{ type: StripItemType }>`
  position: relative;
  height: 100%;
  font-family: "Helvetica";
  white-space: nowrap;
  background-color: ${(p) => {
    switch (p.type) {
      case StripItemType.GreenSeparator:
        return "#005800";
      case StripItemType.RedSeparator:
        return "#b60000";
      case StripItemType.WhiteSeparator:
        return "white";
      case StripItemType.HandwrittenSeparator:
      default:
        return "#f6f9f2";
    }
  }};
`;

export const SeparatorLabel = styled.input`
  border: none;
  width: 90%;
  background: none;
  line-height: 66px;
  font-size: 40px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  color: ${(p) => {
    switch (p.type) {
      case StripItemType.GreenSeparator:
      case StripItemType.RedSeparator:
        return "white";
      case StripItemType.WhiteSeparator:
      case StripItemType.HandwrittenSeparator:
      default:
        return "black";
    }
  }};
  font-family: ${(p) => (p.type === StripItemType.HandwrittenSeparator ? "Handwritten" : "Helvetica")};
  font-weight: ${(p) => (p.type === StripItemType.HandwrittenSeparator ? "bolder" : "normal")};

  &:focus {
    outline: none;
  }
`;

export const HalfStrip = styled.div<{ $rightSide: boolean }>`
  position: relative;
  height: 100%;
  width: 50%;
  white-space: nowrap;
  background-color: #f6f9f2;
  left: ${(p) => p.$rightSide && "50%"};

  &::before {
    content: " ";
    height: 100%;
    width: 100%;
    position: absolute;
    left: ${(p) => (p.$rightSide ? "-100%" : "100%")};
  }
`;

export const HalfStripText = styled.textarea`
  border: none;
  background: none;
  font-size: 18px;
  line-height: 18px;
  font-family: "Handwritten";
  font-weight: bolder;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  resize: none;

  &:focus {
    outline: none;
  }
`;

export const CloneContainer = styled.div`
  > * {
    position: fixed;
    cursor: grabbing;
    z-index: 100;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.5);
    transition: ${`${DROP_ANIMATION_MS}ms`} cubic-bezier(0.2, 1, 0.1, 1);
    transition-property: transform, scale, opacity;
  }
`;

export const DraggingScreen = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  cursor: grabbing;
`;
