import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FocusEvent, useEffect } from "react";
import { CurrentLogin, FacilitiesTable } from "src/components/login";
import { Checkbox, LogoutButton } from "src/components/ui";
import { Sound } from "src/enums";
import { useKey } from "src/hooks";
import {
  configurationSelector,
  defaultRackSelector,
  enabledSoundsSelector,
  facilityMenuIsActiveSelector,
  flightStripsFacilitiesSelector,
  setDefaultRack,
  setFacilityMenuIsActive,
  setSelectedStripItem,
  setSoundOption,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/menus";
import { Hr, VersionNumber } from "src/styles/ui";
import { VERSION } from "src/utils";

function FacilityMenu() {
  const configuration = useAppSelector(configurationSelector)!;
  const facilityMenuIsActive = useAppSelector(facilityMenuIsActiveSelector);
  const facilities = useAppSelector(flightStripsFacilitiesSelector);
  const enabledSounds = useAppSelector(enabledSoundsSelector);
  const defaultRack = useAppSelector(defaultRackSelector);

  const dispatch = useAppDispatch();

  useKey("Escape", () => dispatch(setFacilityMenuIsActive(false)), { facilityMenuIsActive: true });
  useKey("Tab", () => undefined, { facilityMenuIsActive: true });

  useEffect(() => {
    if (facilityMenuIsActive) {
      dispatch(setSelectedStripItem(undefined));
    }
  }, [facilityMenuIsActive]);

  if (!facilityMenuIsActive || !configuration) {
    return undefined;
  }

  const handleRackNumberChange = (e: FocusEvent<HTMLInputElement>) => {
    let value;
    const intValue = parseInt(e.target.value, 10);
    if (!Number.isNaN(intValue)) {
      value = Math.max(1, intValue);
    }
    e.target.value = value?.toString() ?? "";
    dispatch(setDefaultRack(value));
  };

  return (
    <>
      <S.Screen onClick={() => dispatch(setFacilityMenuIsActive(false))} />
      <S.FacilityMenu>
        <S.CloseButton onClick={() => dispatch(setFacilityMenuIsActive(false))}>
          <FontAwesomeIcon icon={faTimes} />
        </S.CloseButton>
        <CurrentLogin showSessionStatus />
        <Hr />
        {facilities.length > 1 && (
          <>
            <S.Subtitle>Change Facility:</S.Subtitle>
            <FacilitiesTable onSelectFacility={() => dispatch(setFacilityMenuIsActive(false))} />
          </>
        )}
        <Checkbox
          label={
            configuration.enableArrivalStrips && configuration.enableSeparateArrDepPrinters
              ? "Play sound when departure flight strip printed"
              : "Play sound when flight strip printed"
          }
          checked={enabledSounds.includes(Sound.Printer)}
          onChange={(checked) => dispatch(setSoundOption({ sound: Sound.Printer, enabled: checked }))}
        />
        {configuration.enableArrivalStrips && configuration.enableSeparateArrDepPrinters && (
          <Checkbox
            label="Play sound when arrival flight strip printed"
            checked={enabledSounds.includes(Sound.ArrivalPrinter)}
            onChange={(checked) => dispatch(setSoundOption({ sound: Sound.ArrivalPrinter, enabled: checked }))}
          />
        )}
        <Checkbox
          label="Play sound when flight strip added to bay"
          checked={enabledSounds.includes(Sound.Slide)}
          onChange={(checked) => dispatch(setSoundOption({ sound: Sound.Slide, enabled: checked }))}
        />
        <br />
        <S.Subtitle>Default Rack:</S.Subtitle>
        <S.RackNumberInput placeholder="1" type="number" defaultValue={defaultRack} onChange={handleRackNumberChange} />
        <br />
        <LogoutButton />
        <S.Version>
          <b>Version</b>
          <VersionNumber>{VERSION}</VersionNumber>
        </S.Version>
      </S.FacilityMenu>
    </>
  );
}

export default FacilityMenu;
